body {
  margin: 0;
  background: rgb(250, 250, 250);
  font-family: "Neue-Light";
  font-size: 18;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: "Neue-Bold";
} */

.ui.grid > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ui.grid > .column:not(.row),
.ui.grid > .row > .column {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: top;
}
@media only screen and (max-width: 767px) {
  .ui.grid .ui.stackable.grid,
  .ui.segment:not(.vertical) .ui.stackable.page.grid {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}

iframe {
  width: 100% !important;
}

.more-link {
  display: none;
}

.dynamic-post li {
  line-height: 1.4285em;
}

.dynamic-post a {
  color: #FF8A3D;
  font-weight: bold;
}

.dynamic-post a:hover {
  text-decoration: underline;
}

figure img {
  max-width: 100%;
  height: auto;
}

.wp-image-8 {
  max-width: 100% !important;
}

.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  display: none;
}

.ui.segment.pushable {
  border: none !important;
  border-radius: 0;
  margin-top: 0;
}


#main-header {
  will-change: transform;
  transition: transform 500ms linear;
}

.visible-header {
  transform: translateY(0%);
}

.hidden-header {
  transform: translateY(-100%);
}

/* -- */

#whatsapp-link {
  will-change: transform;
  transition: transform 500ms linear;
}

#whatsapp-link.visible {
  transform: translateX(0%);
}

#whatsapp-link.hidden {
  transform: translateX(120%);
}

/* -- */

#mobile-options {
  transition: all 300ms ease-in-out;
}

.item.menu:hover {
  background-color: #ff8a3d !important;
  color: #fff !important;
  transition: 0.3s;
}

.ui.checked.fitted.toggle.checkbox label:before {
  background-color: #ff8a3d !important;
}

.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #ff8a3d !important;
}

div.w170 {
  width: 170px !important;
}

.ui.container.top-left {
  margin-left: 8% !important;
}

.ui.dropdown div.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

div.ui.container.grid.removeMarginsX {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

img.icons.ui.image {
  display: inline;
}

ul.pointPrior {
  list-style: none;
  margin: 0;
}

ul.pointPrior li::before {
  content: "\2022";
  color: #547ea0;
  font-weight: bold;
  display: inline-block;
  width: 1em !important;
  margin-left: -1em;
  font-size: 2.5em;
  margin-top: -5px;
}

ul.point {
  list-style: none;
  margin: 0;
}

ul.point li::before {
  content: "\2022";
  color: #7798bf;
  font-weight: bold;
  display: inline-block;
  width: 1em !important;
  margin-left: -1em;
  font-size: 2.5em;
  margin-top: -5px;
}

/* carrusel */
.carousel .slider-wrapper {
  height: auto !important;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  height: auto !important;
}

p h1 h2 h3 h4 h5 h6 {
  color: #3D291C;
}
